<template>
    <div>
        <div v-if="debug === true" class="dev-modal" v-bind:class="{'active': true, 'large': large}">
            <iframe :srcdoc="exception"></iframe>
            <div v-on:click="close" class="button-close"></div>
            <div v-on:click="large = !large" v-bind:class="{resize, 'minimize': large}"></div>
        </div>

        <div v-if="modal === true">
            <div class="modal-overlay"></div>
            <div class="modal-window error">
                <div class="modal-header">
                    <a class="button-close" v-on:click="close()"></a>
                </div>
                <div class="modal-body">
                    <div class="content">
                        <div class="content">
                            <h3>Oops, Something went wrong!</h3>
                            <p>Please try refreshing and contacting us if the problem persists.</p>
                        </div>
                        <a class="button small-button" v-on:click="close()">Close</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
    props: [
      'exception'
    ],
    data () {
      return {
        large: false
      }
    },
    methods: {
      resize: function () {
        this.large = true
      },
      close: function () {
        this.large = false
        this.exception = null
      }
    },

    computed: {
      show: function () {
        return this.exception != null
      },
      modal: function () {
        return this.show && !this.debug;
      },
      debug: function () {
        return this.show && (typeof this.exception === 'string' || this.exception instanceof String);
      }
    }
  };
</script>