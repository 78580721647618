import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'

Vue.use(VueScrollTo);

export default {
    template: '#address-component',
    name: 'AddressComponent',
    props: {
        address: {
            type: Object,
            default: function () {
                return {
                    postCode: '',
                    addressLine1: '',
                    addressLine2: '',
                    addressLine3: '',
                    town: '',
                    county: '',
                    number: '',
                    selected: false,
                }
            }
        },
        additionalDriver: Boolean,
    },
    data () {
        return {
            showLoading: false,
            manualAddress: false,
            manualAddressSelected: false,
            results: [],
            errors: {},
        }
    },
    methods: {
        addressLookup: function(event) {
            event.preventDefault();
            this.showLoading = true;
            this.resetErrors();
            axios.post('/ajax/validate-address-search-input', this.address)
                .then(() => {
                    this.results = [];
                    this.address['postCode'] = this.address['postCode'].replace(/\s/g,'').replace(/.{3}$/,' $&').toUpperCase();
                    axios.post('/ajax/address-search', this.address)
                        .then((response) => {
                            this.showLoading = false;
                            this.results = response.data;

                            // If there's only one result then pre-select it for the user
                            if (this.results.length === 1) {
                                this.chooseAddress(0);
                            }

                            this.$emit('clear-address-errors');
                        });

                })
                .catch((error) => {
                    var errorResponse = error.response.data.errors;
                    var count = 0;
                    for (const property in errorResponse) {
                        // Scroll to the first validation error
                        if (count == 0) {
                            this.$scrollTo('#' + property, 200, { easing: 'linear', offset: -50 });
                        }

                        Vue.set(this.errors, property, errorResponse[property]);
                        count++;
                    }
                    this.showLoading = false;
                });
        },
        chooseAddress: function(index) {
            for (const property in this.results[index]) {
                Vue.set(this.address, property, this.results[index][property]);
                Vue.set(this.address, 'selected', true);
            }
            this.manualAddressSelected = false;
            this.results = [];
            this.$emit('clear-address-errors');
        },
        validateAddress: function() {
            this.resetErrors();
            this.showLoading = true;
            axios.post('/ajax/validate-address', this.address)
                .then(() => {
                    Vue.set(this.address, 'selected', true);
                    this.showLoading = false;
                    this.manualAddress = false;
                    this.manualAddressSelected = true;
                })
                .catch((error) => {
                    var errorResponse = error.response.data.errors;
                    var count = 0;
                    for (const property in errorResponse) {
                        // Scroll to the first validation error
                        if (count == 0) {
                            this.$scrollTo('#' + property, 200, { easing: 'linear', offset: -50 });
                        }

                        Vue.set(this.errors, property, errorResponse[property]);
                        count++;
                    }
                    this.showLoading = false;
                });

        },
        resetErrors: function() {
            this.errors = {};
        }
    },
};
