import Vue from 'vue'
import VueFuse from 'vue-fuse'

Vue.use(VueFuse);

export default {
    template: '#conditions-component',
    name: 'MedicalConditionsComponent',
    props: ['conditions', 'additionalDriver'],
    data () {
        return {
            showLoading: true,
            showConditionForm: false,
            editingCondition: false,
            editingConditionIndex: 0,
            conditionTypeResults: [],
            conditionTypes: [],
            condition: {
                medicalConditionMonths: '',
                medicalConditionYears: '',
                knownAboutDate: '',
                conditionType: '',
                medicalConditionTypeCode: '',
                reportedToDvlaCode: '',
            },
            errors: {},
        }
    },
    methods: {
        resetCondition: function () {
            this.condition = {
                medicalConditionMonths: '',
                medicalConditionYears: '',
                knownAboutDate: '',
                conditionType: '',
                medicalConditionTypeCode: '',
                reportedToDvlaCode: '',
            };
            this.editingCondition = false;
            this.showConditionForm = false;
            Vue.nextTick(() => {
                this.$scrollTo('#medicalConditions', 200, { easing: 'linear', offset: -50 });
            });
        },
        addCondition: function (add = true) {
            if (
                (this.condition.medicalConditionMonths !== '' && this.condition.medicalConditionMonths !== 0)
                &&
                (this.condition.medicalConditionYears !== '' && this.condition.medicalConditionYears !== 0)
            ) {
                var date = new Date();
                date.setMonth(date.getMonth() - this.condition.medicalConditionMonths);
                date.setFullYear(date.getFullYear() - this.condition.medicalConditionYears);
                this.condition.knownAboutDate = date.toISOString();
            }
            this.resetErrors();
            this.showLoading = true;
            axios.post('/ajax/medical-conditions/validate', this.condition)
                .then(() => {
                    if (add) {
                        this.conditions.push(this.condition);
                    } else {
                        this.conditions[this.editingConditionIndex] = this.condition;
                    }
                    this.saveConditions();
                    this.resetCondition();
                    this.editingCondition = false;
                    Vue.nextTick(() => {
                        this.$scrollTo('#medicalConditions', 200, { easing: 'linear', offset: -50 });
                    });
                    this.showLoading = false;
                })
                .catch((error) => {
                    var errorResponse = error.response.data.errors;
                    var count = 0;
                    for (const property in errorResponse) {
                        // Scroll to the first validation error
                        if (count == 0) {
                            this.$scrollTo('#' + property, 200, { easing: 'linear', offset: -50 });
                        }

                        Vue.set(this.errors, property, errorResponse[property]);
                        count++;
                    }
                    this.showLoading = false;
                });
        },
        saveConditions: function () {
            if (!this.additionalDriver) {
                axios.post('/ajax/medical-conditions/store-all', {
                    conditions: this.conditions
                });
            }
        },
        resetErrors: function () {
            this.errors = {};
        },
        showAddConditionForm: function () {
            this.showConditionForm = true;
            Vue.nextTick(() => {
                this.$scrollTo('#conditions-form', 200, { easing: 'linear', offset: -50 });
            });
        },
        editCondition: function (index) {
            // Copy the object to ensure that we don't use a reference (prevents changes from being stored when cancelled)
            this.condition = Object.assign({}, this.conditions[index]);
            this.showConditionForm = true;
            this.editingCondition = true;
            this.editingConditionIndex = index;
            this.showAddConditionForm();
        },
        deleteCondition: function (index) {
            this.conditions.splice(index, 1);
            this.saveConditions();
        },
        selectConditionType: function (code, text) {
            this.conditionTypeResults = [];
            this.condition.medicalConditionTypeCode = code;
            this.condition.conditionType = text;
        },
    },
    mounted: function () {
        axios.get('/ajax/medical-conditions/retrieve-types')
            .then((response) => {
                this.conditionTypes = response.data;
                this.showLoading = false;
            });
    },
    watch: {
        'condition.conditionType' () {
            this.$search(this.condition.conditionType, this.conditionTypes, { keys: ['text'] }).then(results => {
                if (results[0] && results[0].text === this.condition.conditionType) {
                    // If the term exactly matches then it's likely a selected option
                    return;
                }
                this.conditionTypeResults = results;
            })
        }
    },
};
