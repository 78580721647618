<template>
    <div>
        <div v-if="show">
            <div class="modal-overlay"></div>
            <div class="modal-window large-modal">
                <div class="modal-header">
                    <a href="#" class="button-close" v-on:click="close()"></a>
                </div>
                <div class="modal-body">
                    <div class="content-mileage-calculator">
                        <h3>Check the number of miles you need</h3>
                        <p>Enter your regular journeys below to calculate your estimated annual miles</p>
                        <div class="calculator">
                            <div class="calculator-body">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>To/From</th>
                                        <th>Mileage</th>
                                        <th>How Often?</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="n in numberOfRows">
                                        <td>
                                                <span class="input-wrapper">
                                                    <input type="text" id="journey0" name="journey0" value="">
                                                </span>
                                        </td>
                                        <td>
                                            <select id="mileage0" name="mileage0" class="" v-model="mileage[n]"
                                                    v-on:change="calculateMileage">
                                                <option value="" disabled="">Select mileage</option>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value="20">20</option>
                                                <option value="25">25</option>
                                                <option value="30">30</option>
                                                <option value="35">35</option>
                                                <option value="40">40</option>
                                                <option value="45">45</option>
                                                <option value="50">50</option>
                                                <option value="55">55</option>
                                                <option value="60">60</option>
                                                <option value="65">65</option>
                                                <option value="70">70</option>
                                                <option value="75">75</option>
                                                <option value="80">80</option>
                                                <option value="85">85</option>
                                                <option value="90">90</option>
                                                <option value="95">95</option>
                                                <option value="100">100</option>
                                                <option value="105">105</option>
                                                <option value="110">110</option>
                                                <option value="115">115</option>
                                                <option value="120">120</option>
                                                <option value="125">125</option>
                                                <option value="130">130</option>
                                                <option value="135">135</option>
                                                <option value="140">140</option>
                                                <option value="145">145</option>
                                                <option value="150">150</option>
                                                <option value="155">155</option>
                                                <option value="160">160</option>
                                                <option value="165">165</option>
                                                <option value="170">170</option>
                                                <option value="175">175</option>
                                                <option value="180">180</option>
                                                <option value="185">185</option>
                                                <option value="190">190</option>
                                                <option value="195">195</option>
                                                <option value="200">200</option>
                                                <option value="205">205</option>
                                                <option value="210">210</option>
                                                <option value="215">215</option>
                                                <option value="220">220</option>
                                                <option value="225">225</option>
                                                <option value="230">230</option>
                                                <option value="235">235</option>
                                                <option value="240">240</option>
                                                <option value="245">245</option>
                                                <option value="250">250</option>
                                                <option value="255">255</option>
                                                <option value="260">260</option>
                                                <option value="265">265</option>
                                                <option value="270">270</option>
                                                <option value="275">275</option>
                                                <option value="280">280</option>
                                                <option value="285">285</option>
                                                <option value="290">290</option>
                                                <option value="295">295</option>
                                                <option value="300">300</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select id="frequency0" name="frequency0" class="" v-model="frequency[n]"
                                                    v-on:change="calculateMileage">
                                                <option value="" disabled="">Select frequency</option>
                                                <option value="1">Once a year</option>
                                                <option value="2">Every 6 months</option>
                                                <option value="2.4">Every 5 months</option>
                                                <option value="3">Every 4 months</option>
                                                <option value="4">Every 3 months</option>
                                                <option value="6">Every 2 months</option>
                                                <option value="12">Every month</option>
                                                <option value="17.333333333333332">Every 3 weeks</option>
                                                <option value="26">Every 2 weeks</option>
                                                <option value="52">Once a week</option>
                                                <option value="104">Twice a week</option>
                                                <option value="156">Three times a week</option>
                                                <option value="208">Four times a week</option>
                                                <option value="260">Five times a week</option>
                                                <option value="312">Six times a week</option>
                                                <option value="365">Daily</option>
                                                <option value="730">Twice a day</option>
                                                <option value="1095">Three times a day</option>
                                                <option value="1460">Four times a day</option>
                                            </select>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <button class="button small-button grey" v-on:click="addRow">Add another journey
                                </button>
                            </div>
                            <div class="calculator-footer">Estimated Miles: @{{ mileageTotal }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

  export default {
    name: 'MileageCalculatorComponent',
    props: ['show'],
    data () {
      return {
        mileage: [],
        frequency: [],
        numberOfRows: 5,
        mileageTotal: 0,
      }
    },
    methods: {
      close: function(){
        this.$emit('closed', true)
      },
      calculateMileage: function () {
        var calculatedMiles = 0;
        for (const miles in this.mileage) {
          if (!isNaN(this.mileage[miles]) && !isNaN(this.frequency[miles])) {
            calculatedMiles += this.mileage[miles] * this.frequency[miles];
          }
        }
        this.mileageTotal = Math.round(calculatedMiles);
        this.$emit('input', this.mileageTotal);
      },
      addRow: function () {
        this.numberOfRows++;
        this.mileage[this.numberOfRows] = '';
        this.frequency[this.numberOfRows] = '';
      },
    },
    mounted: function () {
      // Sets up array so that the default dropdown options are displayed
      for (var i = 0; i <= this.numberOfRows; i++) {
        this.mileage[i] = '';
        this.frequency[i] = '';
      }
    }
  };
</script>