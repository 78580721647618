import Vue from 'vue'
import numeral from 'numeral'
import VueFuse from 'vue-fuse'

Vue.use(VueFuse);

Vue.filter("formatDate", function (value) {
    return numeral(value).format("00"); // displaying other groupings/separators is possible, look at the docs
});

export default {
    template: '#convictions-component',
    name: 'ConvictionsComponent',
    props: ['convictions', 'additionalDriver'],
    data () {
        return {
            showLoading: true,
            convictionTypes: [],
            convictionTypeResults: [],
            showConvictionForm: false,
            editingConviction: false,
            editingConvictionIndex: 0,
            conviction: {
                dayOfConviction: '',
                monthOfConviction: '',
                yearOfConviction: '',
                convictionDate: '',
                convictionType: '',
                convictionCode: '',
                pointsIncurred: '',
                fineIncurred: '',
                lengthOfBan: '',
                wasBreathalysed: false,
                breathalyserReading: 0,
                convictionAccidentRelated: false,
            },
            errors: {},
        }
    },
    methods: {
        resetConviction: function () {
            this.conviction = {
                dayOfConviction: '',
                monthOfConviction: '',
                yearOfConviction: '',
                convictionDate: '',
                convictionType: '',
                convictionCode: '',
                pointsIncurred: '',
                fineIncurred: '',
                lengthOfBan: '',
                wasBreathalysed: false,
                breathalyserReading: 0,
                convictionAccidentRelated: false,
            };
            this.editingConviction = false;
            this.showConvictionForm = false;
            Vue.nextTick(() => {
                this.$scrollTo('#convictions', 200, { easing: 'linear', offset: -50 });
            });
        },
        addConviction: function (add = true) {
            if (this.conviction.yearOfConviction !== '' && this.conviction.monthOfConviction !== '' && this.conviction.dayOfConviction !== '') {
                // Subtract one from conviction month as it needs to be an index (starting at 0)
                let monthOfConviction = this.conviction.monthOfConviction - 1;
                this.conviction.convictionDate = new Date(Date.UTC(this.conviction.yearOfConviction, monthOfConviction, this.conviction.dayOfConviction, 0, 0, 0)).toISOString();
            }
            this.resetErrors();
            this.showLoading = true;
            axios.post('/ajax/validate-conviction', this.conviction)
                .then(() => {
                    if (add) {
                        this.convictions.push(this.conviction);
                    } else {
                        this.convictions[this.editingConvictionIndex] = this.conviction;
                    }
                    this.saveConvictions();
                    this.resetConviction();
                    this.editingConviction = false;
                    Vue.nextTick(() => {
                        this.$scrollTo('#convictions', 200, { easing: 'linear', offset: -50 });
                    });
                    this.showLoading = false;
                })
                .catch((error) => {
                    var errorResponse = error.response.data.errors;
                    var count = 0;
                    for (const property in errorResponse) {
                        // Scroll to the first validation error
                        if (count == 0) {
                            this.$scrollTo('#' + property, 200, { easing: 'linear', offset: -50 });
                        }

                        Vue.set(this.errors, property, errorResponse[property]);
                        count++;
                    }
                    this.showLoading = false;
                });
        },
        saveConvictions: function () {
            if (!this.additionalDriver) {
                axios.post('/ajax/store-all-convictions', {
                    convictions: this.convictions
                });
            }
        },
        resetErrors: function() {
            this.errors = {};
        },
        showAddConvictionForm: function () {
            this.showConvictionForm = true;
            Vue.nextTick(() => {
                this.$scrollTo('#add-box', 200, { easing: 'linear', offset: -50 });
            });
        },
        editConviction: function (index) {
            // Copy the object to ensure that we don't use a reference (prevents changes from being stored when cancelled)
            this.conviction = Object.assign({}, this.convictions[index]);
            this.editingConviction = true;
            this.editingConvictionIndex = index;
            this.showAddConvictionForm();
        },
        deleteConviction: function (index) {
            this.convictions.splice(index, 1);
            this.saveConvictions();
        },
        selectConvictionType: function (code, text) {
            this.convictionTypeResults = [];
            this.conviction.convictionCode = code;
            this.conviction.convictionType = text;
        },
    },
    mounted: function () {
        axios.get('/ajax/retrieve-conviction-types')
            .then((response) => {
                this.convictionTypes = response.data;
                this.showLoading = false;
            });
    },
    watch: {
        'conviction.convictionType' () {
            this.$search(this.conviction.convictionType, this.convictionTypes, { keys: ['text'] }).then(results => {
                if (results[0] && results[0].text === this.conviction.convictionType) {
                    // If the term exactly matches then it's likely a selected option
                    return;
                }
                this.convictionTypeResults = results;
            })
        },
    },
};
