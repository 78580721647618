<template>
    <carousel
            :autoplay=false
            :interval="8000"
            :data="slides"
            :controls=false
            :slide-on-swipe=true
            indicator-type="disc"
            class="mx-auto center-block">

    </carousel>
</template>
<script>

  import VueCarousel from '@chenfengyuan/vue-carousel';

  export default {
    components: {
      'carousel': VueCarousel
    },
    data () {
      return {
        slides: [
          '<div class="center-block d-block mx-auto"><img src="/images/Customers.PNG" class="img-fluid mx-auto slide-image shadow-sm p-3 mb-5 bg-white rounded" /></div>',
          '<div class="center-block d-block mx-auto"><img src="/images/SafeDriver.PNG" class="img-fluid mx-auto slide-image shadow-sm p-3 mb-5 bg-white rounded" /></div>',
          '<div class="center-block d-block mx-auto"><img src="/images/Curfew.PNG" class="img-fluid mx-auto slide-image shadow-sm p-3 mb-5 bg-white rounded" /></div>',
          '<div class="center-block d-block mx-auto"><img src="/images/Tracking.PNG" class="img-fluid mx-auto slide-image shadow-sm p-3 mb-5 bg-white rounded" /></div>'
        ]
      }
    }
  };
</script>