import Vue from "vue";
import numeral from 'numeral';

Vue.filter("formatDate", function (value) {
    return numeral(value).format("00"); // displaying other groupings/separators is possible, look at the docs
});

export default {
    template: '#claims-component',
    name: 'ClaimsComponent',
    props: ['claims', 'additionalDriver', 'additionalDriverIndex'],
    data () {
        return {
            showLoading: false,
            showClaimForm: false,
            editingClaim: false,
            editingClaimIndex: 0,
            claim: {
                claimFaultType: '',
                dayOfClaim: '',
                monthOfClaim: '',
                yearOfClaim: '',
                dateOfClaim: '',
                costOfClaim: '',
                ownCosts: '',
                personalInjuryCosts: '',
                thirdPartyCosts: '',
                incidentType: '',
                incidentTypeCode: '',
                accidentSettled: '',
                accidentAnybodyInjured: '',
                accidentSingleVehicleAccident: '',
                accidentDriverAtFault: '',
                theftContentsStolen: '',
                theftVehicleStolen: '',
                theftLocation: '',
                incidentTypeExpanded: false,
            },
            errors: [],
        }
    },
    methods: {
        resetClaim: function () {
            this.claim = {
                claimFaultType: '',
                dayOfClaim: '',
                monthOfClaim: '',
                yearOfClaim: '',
                dateOfClaim: '',
                costOfClaim: '',
                ownCosts: '',
                personalInjuryCosts: '',
                thirdPartyCosts: '',
                incidentType: '',
                incidentTypeCode: '',
                accidentSettled: '',
                accidentAnybodyInjured: '',
                accidentSingleVehicleAccident: '',
                accidentDriverAtFault: '',
                theftContentsStolen: '',
                theftVehicleStolen: '',
                theftLocation: '',
                incidentTypeExpanded: false,
            };
            this.editingClaim = false;
            this.showClaimForm = false;
            Vue.nextTick(() => {
                this.$scrollTo('#claims', 200, { easing: 'linear', offset: -50 });
            });
        },
        incidentChange: function (incidentTypeText) {
            this.claim.incidentType = incidentTypeText;
            this.claim.accidentSettled = '';
            this.claim.accidentAnybodyInjured = '';
            this.claim.accidentSingleVehicleAccident = '';
            this.claim.accidentDriverAtFault = '';
            this.claim.theftContentsStolen = '';
            this.claim.theftVehicleStolen = '';
            this.claim.theftLocation = '';
        },
        addClaim: function () {
            if (this.claim.yearOfClaim !== '' && this.claim.monthOfClaim !== '' && this.claim.dayOfClaim !== '') {
                // Subtract one from conviction month as it needs to be an index (starting at 0)
                let claimMonth = this.claim.monthOfClaim - 1;
                this.claim.dateOfClaim = new Date(Date.UTC(this.claim.yearOfClaim, claimMonth, this.claim.dayOfClaim, 0, 0, 0)).toISOString();
            }
            this.resetErrors();
            this.showLoading = true;
            axios.post('/ajax/validate-claim', this.claim)
                .then(() => {
                    if (this.editingClaim) {
                        this.claims[this.editingClaimIndex] = this.claim;
                    } else {
                        this.claims.push(this.claim);
                    }
                    this.saveClaims();
                    this.resetClaim();
                    this.editingClaim = false;
                    Vue.nextTick(() => {
                        this.$scrollTo('#claims', 200, { easing: 'linear', offset: -50 });
                    });
                    this.showLoading = false;
                })
                .catch((error) => {
                    var errorResponse = error.response.data.errors;
                    var count = 0;
                    for (const property in errorResponse) {
                        // Scroll to the first validation error
                        if (count == 0) {
                            this.$scrollTo('#' + property, 200, { easing: 'linear', offset: -50 });
                        }

                        Vue.set(this.errors, property, errorResponse[property]);
                        count++;
                    }
                    this.showLoading = false;
                });
        },
        saveClaims: function () {
            axios.post('/ajax/store-all-claims', {
                additionalDriver: this.additionalDriver,
                additionalDriverIndex: this.additionalDriverIndex,
                claims: this.claims
            });
        },
        resetErrors: function () {
            this.errors = [];
        },
        showAddClaimForm: function () {
            this.showClaimForm = true;
            Vue.nextTick(() => {
                this.$scrollTo('#claims-add-box', 200, { easing: 'linear', offset: -50 });
            });
        },
        editClaim: function (index) {
            this.claim = this.claims[index];
            this.editingClaim = true;
            this.editingClaimIndex = index;
            this.showAddClaimForm();
        },
        deleteClaim: function (index) {
            this.claims.splice(index, 1);
            this.saveClaims();
        }
    },
};
